import React from "react";
import Container from "../../../components/Container";
import classes from "./DSG.module.css";
import PDFViewer from "../../../components/PDFViewer";

const  DSG = () => {
    return (
        <Container showNavbar={false}>
            <div className={classes.designContainer + " content-container"}>
                <h3>Datenschutzerklärung</h3>
                <PDFViewer url={"/rechtliches/privacy-policy-94250935.pdf"}/>
            </div>
        </Container>
    );
}

export default DSG;