import React from "react";
import classes from "./Landingpage.module.css";
import Container from "../../components/Container";

const Landingpage = () => {
    return (
        <Container showNavbar={false}>
            <div className={classes.container}>
                <div className={classes.textContainer}>
                    <div className={classes.verticalLine}/>
                    <h1>Das innovativste Reservierungstool für exklusive Restaurants<br/>und die smarteste Möglichkeit einen passenden Tisch zu reservieren</h1>
                    <div className={classes.line}/>
                    <p style={{marginTop: "10%", marginBottom: "10%"}}>Immer ein Tisch bereit, wenn du es bist.</p>
                    <a style={{marginTop: "10%", marginBottom: "10%"}} href="https://linktr.ee/appdinp" className={classes.link}>Kostenlos herunterladen</a>
                    <a style={{marginTop: "10%", marginBottom: "10%"}} href="https://doodle.com/bp/dinp/vorstellungs-event-app-dinp" className={classes.link}>Gratis Beratungstermin</a>
                </div>
            </div>
        </Container>
    );
}

export default Landingpage;