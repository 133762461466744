import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import classes from "./Header.module.css";
import {Button, IconButton} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        if(location.pathname === '/') {
            navigate("/kontakt")
        }
    }

    const handleLogoClick = () => {
        navigate("/")
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" className={classes.header}>
                <Toolbar style={{backgroundColor: "white", padding: 0, boxShadow: 'none', height: 30}}
                         sx={{justifyContent: 'flex-end'}}>
                    <IconButton  style={{padding: 0, marginTop: 8, marginRight: "auto", marginLeft: 0}}
                        onClick={handleClick}
                    >
                        <img src="/images/menu_icon.jpeg" width={"20"} height={"20"} alt="menu"/>
                    </IconButton>
                    <button className={classes.headerTitle} onClick={handleLogoClick}>dinp</button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;