import * as React from 'react';
import classes from './Footer.module.css';
import {Grid} from "@mui/material";

const Footer = () => {

    return (
        <footer>
            <Grid container spacing={1} className={classes.footerContent}>
                <Grid item xs={12} style={{paddingRight: 15}}>
                    <p style={{textAlign: "right"}}><strong>dinp</strong> | <a href={"/agb"} style={{textDecoration: "none", color: "black"}}>Allgemeine Geschäftsbedingungen</a> (AGB) | <a href={"/dsg"} style={{textDecoration: "none", color: "black"}}>Datenschutzerklärung</a> | <a href={"/cookies"} style={{textDecoration: "none", color: "black"}}>Cookie-Policy</a> | Postfach 11 | 9542 Münchwilen</p>
                </Grid>
                <Grid item xs={12}>
                    {
                        /* TODO: <p>&copy; 2023 dinp. All rights reserved.</p>
                    <ul className={classes.footerLinks}>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms of Service</a></li>
                        <li><a href="#">Contact Us</a></li>
                    </ul>
                    */}
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;