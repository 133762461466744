import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import classes from "./Navbar.module.css";
import {Grid} from "@mui/material";
import NavbarSecond from "../navbar-second/NavbarSecond";

const Navbar = () => {

    const location = useLocation();

    return (
        <Grid container spacing={2} className={classes.navbar}>
            <Grid item xs={12}>
                <div className={classes.navDivider}></div>
                <Link to="/kontakt"
                      className={`${classes.navLink} ${location.pathname.startsWith('/kontakt') ? classes.boldNavLink : ''}`}
                >
                    Kontakt
                </Link>
                <div className={classes.navDivider}></div>
            </Grid>
            <Grid item xs={6}>
                {/*<NavbarSecond/>*/}
            </Grid>
        </Grid>
    );
};

export default Navbar;
