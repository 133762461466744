import React from "react";
import Container from "../../components/Container";
import classes from "./Contact.module.css";
import {Grid} from "@mui/material";
import {Outlet} from "react-router-dom";

const Contact = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"} style={{marginRight: 10}}>
                <Grid container spacing={1} className={classes.container}>
                    <Grid item xs={12} style={{height: 650, position: "relative", paddingRight: "3%"}}>
                        <p style={{textAlign: "right"}}>Kontaktdaten</p>
                        <p style={{textAlign: "right"}}>
                            <strong>dinp</strong>
                            <br />
                            <br />
                            Postfach 11
                            <br />
                            9542 Münchwilen
                            <br />
                            <br />
                            <a href="mailto:info@dinp.ch">
                                info@dinp.ch
                            </a>
                        </p>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default Contact;