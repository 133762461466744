import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "./fonts/DINAlternateBold.otf";
import "./fonts/DINAlternateLight.ttf";
import "./fonts/DINAlternateRegular.otf";
import Landingpage from "./routes/landingpage/Landingpage";
import AGB from "./routes/rechtliches/agb/AGB";
import DSG from "./routes/rechtliches/dsg/DSG";
import Cookies from "./routes/rechtliches/cookies/Cookies";
import Contact from "./routes/contact/Contact";

const router = createBrowserRouter([
    {path: '/', element: <Landingpage/>},
    {path: '/agb', element: <AGB/>},
    {path: '/dsg', element: <DSG/>},
    {path: '/kontakt', element: <Contact/>},
    {path: '/cookies', element: <Cookies/>},
    {path: '*', element: <Landingpage/>}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
