import React from "react";

const PDFViewer = ({ url }) => {
    return (
        <iframe
            src={url}
            width="100%"
            height="800px"
            style={{ border: 'none' }}
            title="PDF Viewer"
        ></iframe>
    );
};

export default PDFViewer;